<template>
  <div class="oauth2">
    <div class="qt-block-ui" v-if="checking"></div>
    <div class="error" v-else>
      <div class="details">
        <div class="m-5">
          <h4>
            <i class="fas fa-exclamation-triangle"></i> {{$t('warnings.error-occur')}}
          </h4>
          <hr>
          <p class="mb-5">{{error}}</p>
          <router-link to="/login" class="btn btn-primary btn-block">{{$t('warnings.try-again')}}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthSocial',
  data() {
    return {
      error: '',
      checking: true,
    };
  },
  mounted() {
    const params = new URLSearchParams(window.location.hash.substr(1));

    if (params.has('token')) {
      localStorage.setItem('yup_chat_token', JSON.stringify(params.get('token')));
      this.$router.replace({ path: '/' });
    } else if (params.has('error')) {
      this.checking = false;
      this.error = params.get('error');
    } else {
      this.checking = false;
      this.error = this.$t('warnings.unknown-error');
    }
  },
};
</script>

<style scoped lang="scss">
.oauth2 {
  height: 80%;
}
.qt-block-ui {
  position: absolute !important;
}

.error {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details {
  width: 100%;
}
</style>
